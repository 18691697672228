import { SearchClient as TypesenseSearchClient } from 'typesense';
import {useListingStore} from '~/store/listing';
import {useDevelopmentStore} from '~/store/development';
import {useCompanyStore} from '~/store/company';
import {useFeaturedStore} from '~/store/featured';
import {FeaturedCategory} from '~/@types/featured';
import {AdDisplayLocation, ListingCustomSearchParams} from '~/@types/listing';
import guid from '~/composables/guid';
import ListingSearchQueryHandler from '~/composables/listingSearchQuery';
import DevelopmentSearchQueryHandler from '~/composables/developmentSearchQuery';
import {NuxtApp} from '#app';

/**
 * Listing Search Plugin
 */
export default defineNuxtPlugin(({$config}) => {
  const typesenseClient = new TypesenseSearchClient({
    'nodes': [{
      'host': 'search.newhomeshub.co.nz', // For Typesense Cloud use xxx.a1.typesense.net
      'port': 443,      // For Typesense Cloud use 443
      'protocol': 'https',   // For Typesense Cloud use https
    }],
    'apiKey': $config.public.searchApiKey,
    'connectionTimeoutSeconds': 2,
  });

  const {appEnv} = $config.public;
  const {$metaPixelTrackEvent}: NuxtApp = useNuxtApp();

  /**
   * Listing Search
   *
   * @param setListingStore
   * @param customParams
   * @param returnFullObject
   */
  const listingSearch = async (setListingStore = true, customParams?: ListingCustomSearchParams, returnFullObject = false) => {
    const listingStore = useListingStore();

    if (setListingStore) {
      listingStore.setIsLoading(true);
    }

    let searchParameters: { [key: string]: string | number } | null = null;
    if (setListingStore) {
      const typesenseSearchParams = ListingSearchQueryHandler.getTypesenseQuery();
      let companySlugFilter = '';
      if (listingStore.companySlug) {
        companySlugFilter += ' && ';
        companySlugFilter += `company.slug:${listingStore.companySlug}`;
      }

      const dynamicSorting = typesenseSearchParams.sortBy !== 'priority:desc' ? `${typesenseSearchParams.sortBy}` : 'randomizer:asc';

      searchParameters = {
        'q': typesenseSearchParams.keyword || '',
        'query_by': typesenseSearchParams.keyword ? 'address, addressOverride' : 'name, address, addressOverride, description, company, development, features, searchHelper',
        'include_fields': 'id, name, slug, listingTypes, heroImageUrl, bedrooms, bathrooms, garageSpaces, floorArea, landArea, price, hidePrice, company, address, addressOverride, isSold, category, adDestinationUrl',
        'filter_by': `((category:=Ad && status:=ACTIVE && adDisplayLocations:=listing_search_widget) || (status:=ACTIVE && adDisplayLocations:=listing_search_widget${typesenseSearchParams.filterBy ? ' && ' + typesenseSearchParams.filterBy : ''}${companySlugFilter}))`,
        'page': typesenseSearchParams.page,
        'sort_by': `isSold:asc,priority:asc,${dynamicSorting}`,
        'per_page': 48,
      };

      // Trigger pixel event
      if (typeof window !== 'undefined' && (typesenseSearchParams.filterBy || companySlugFilter)) {
        $metaPixelTrackEvent('Search', { search_string: JSON.stringify({ filter_by: (typesenseSearchParams.filterBy ? '&&' : '') + typesenseSearchParams.filterBy + companySlugFilter, page: typesenseSearchParams.page }), content_type: 'Listing' });
      }
    } else {
      searchParameters = {
        'q': customParams?.searchKeyword || '*',
        'query_by': customParams?.searchKeyword ? 'address, addressOverride' : listingStore.searchFields.join(', '),
        'include_fields': 'id, name, slug, listingTypes, heroImageUrl, bedrooms, bathrooms, price, garageSpaces, floorArea, landArea, company, address, addressOverride, isSold, category, adDestinationUrl',
        'filter_by': `category:=Listing && status:=ACTIVE && adDisplayLocations:=listing_search_widget${customParams?.filterBy ? ' && ' + customParams?.filterBy : ''}`,
        'page': customParams?.page || 1,
        'sort_by': 'isSold:asc,priority:desc',
        'per_page': customParams?.perPage || 8,
        'query_by_weights': 'name:10, address:8, addressOverride:9, description:1, company:10, development:5, features:5, searchHelper:15',
        'hidden_hits': customParams?.hiddenHitDids || '',
      };
    }

    listingStore.setRequestId(guid());

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const results = await typesenseClient.collections(`${appEnv || 'production'}_listings`).documents().search(searchParameters, {});

    if (setListingStore) {
      if (results && Array.isArray(results.hits)) {
        return results;
      }
    } else if (results && Array.isArray(results.hits)) {
      return returnFullObject ? results : results.hits;
    }
  };

  const adSearch = async (adDisplayLocations: AdDisplayLocation[] = [], perPage = 48) => {
    // Initialize the filter conditions with the static filter
    const filterConditions = ['status:=ACTIVE', 'category:=Ad'];

    // Build the dynamic filter for adDisplayLocations if it's not empty
    if (adDisplayLocations.length > 0) {
      const locationFilters = adDisplayLocations.map(location => `adDisplayLocations:=${location}`).join(' OR ');
      filterConditions.push(locationFilters);
    }

    const searchParameters = {
      'q': '*',
      'query_by': 'adDisplayLocations',
      'include_fields': 'id, name, slug, listingTypes, heroImageUrl, bedrooms, bathrooms, price, garageSpaces, floorArea, landArea, company, address, addressOverride, isSold, category, adDestinationUrl, adOtherImageUrls',
      'filter_by': filterConditions.join(' && '),
      'page': 1,
      'sort_by': 'isSold:asc,priority:desc',
      'per_page': perPage,
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const results = await typesenseClient.collections(`${appEnv || 'production'}_listings`).documents().search(searchParameters, {});

    if (results && Array.isArray(results.hits)) {
      return results;
    }
  };

  /**
   * Development Search
   */
  const developmentSearch = async () => {
    const developmentStore = useDevelopmentStore();

    developmentStore.setIsLoading(true);
    const typesenseSearchParams = DevelopmentSearchQueryHandler.getTypesenseQuery();

    const searchParameters = {
      'q': typesenseSearchParams.keyword,
      'query_by': developmentStore.searchFields.join(', '),
      'include_fields': 'id, name, slug, heroImageUrl, regionName, shortDescription, address, addressOverride, type',
      'filter_by': `status:=ACTIVE ${typesenseSearchParams.filterBy ? ' && ' : ''} ` +  typesenseSearchParams.filterBy,
      'page': typesenseSearchParams.page,
      'sort_by': `${developmentStore.sortBy || 'name:asc'}`,
      'per_page': 48,
      'query_by_weights': 'name:10, regionName: 12, suburbName: 12, address:8, addressOverride:9, description:1, shortDescription:1, keyInfo:5',
    };
    developmentStore.setRequestId(guid());

    // Trigger pixel event
    if (typeof window !== 'undefined' && typesenseSearchParams.filterBy) {
      $metaPixelTrackEvent('Search', {search_string: JSON.stringify({filter_by: typesenseSearchParams.filterBy}), content_type: 'Development'});
    }

    const results = await typesenseClient.collections(`${appEnv || 'production'}_developments`).documents().search(searchParameters, {});

    if (results && Array.isArray(results.hits)) {
      return results;
    }
  };

  /**
   * Company Search
   */
  const companySearch = async () => {
    const companyStore = useCompanyStore();

    companyStore.setIsLoading(true);
    const getFilterBy = () => {
      let filterByString = '(profileType:builder || profileType:Parent)';
      for (const filterByItem of companyStore.queryOptions.filterBy) {
        if (filterByItem.field && filterByItem.value) {
          if (filterByString !== '') {
            filterByString += ' && ';
          }
          filterByString += `${filterByItem.field}:${filterByItem.value}`;
        }
      }
      return filterByString;
    };

    const filterBy = getFilterBy();

    const searchParameters = {
      'q': companyStore.queryOptions.searchKeyword || '',
      'query_by': 'name, displayName, ownershipStructure, overview, keyInformation, services, warranty, cities, regionNames, searchHelper',
      'include_fields': 'id, name, slug, logoUrl, displayName, ownershipStructure, services, warranty, childCompanies, status',
      'filter_by': 'status:=ACTIVE && ' +filterBy,
      'page': companyStore.queryOptions.page,
      'sort_by': `${companyStore.sortBy || 'name:asc'}`,
      'per_page': 96,
      'query_by_weights': 'name:10, displayName:11, ownershipStructure:2, overview:2, keyInformation:8, services:9, warranty:2, cities:5, regionNames:8, searchHelper:12',
    };
    companyStore.setRequestId(guid());

    // Trigger pixel event
    if (typeof window !== 'undefined' && ((filterBy && filterBy.length > 20) || companyStore.queryOptions.searchKeyword)) {
      $metaPixelTrackEvent('Search', {search_string: JSON.stringify({filter_by: getFilterBy(), page: companyStore.queryOptions.page}), content_type: 'Company'});
    }

    const results = await typesenseClient.collections(`${appEnv || 'production'}_companies`).documents().search(searchParameters, {});

    if (results && Array.isArray(results.hits)) {
      companyStore.setCompanyData(results);
    }
    companyStore.setIsLoading(false);
  };

  /**
   * Get featured items
   */
  const featured = async () => {
    const searchParameters = {
      'q': '*',
      'query_by': 'type',
      'include_fields': 'entityType, order, type, widget',
      'page': 1,
      'sort_by': 'type:asc',
      'per_page': 120,
    };
    const results = await typesenseClient.collections(`${appEnv || 'production'}_fixed_featured`).documents().search(searchParameters, {});

    if (results && Array.isArray(results.hits)) {
      const featuredStore = useFeaturedStore();
      const featuredCategories: { field: FeaturedCategory; entityType: string }[] = [
        {field: 'houseAndLand', entityType: 'House & Land'}, {field: 'lands', entityType: 'Land'},
        {field: 'showhomes', entityType: 'Showhome'}, {field: 'designs', entityType: 'Design'},
        {field: 'developments', entityType: 'Development'}, {field: 'builders', entityType: 'Builder'},
      ];

      for (const featuredCategory of featuredCategories) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const items = results.hits.filter((hit) => hit.document.entityType === featuredCategory.entityType);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const widgets = items.map((item: unknown) => item.document.widget);
        featuredStore.setFeaturedItems(featuredCategory.field, widgets);
      }
    }
  };

  /**
   * Get featured items
   */
  const favorites = async (type: string, dids: string[]) => {
    let collectionName = 'listings';
    let includeFields = 'id, name, slug, listingTypes, heroImageUrl, bedrooms, bathrooms, garageSpaces, floorArea, company, address, addressOverride, isSold';

    switch (type) {
      case 'builders':
        collectionName = 'companies';
        includeFields = 'id, name, slug, logoUrl, bannerUrl, displayName, ownershipStructure, services, warranty';
        break;
      case 'developments':
        collectionName = 'developments';
        includeFields = 'id, name, slug, heroImageUrl, regionName, shortDescription, address, addressOverride, type';
        break;
    }

    const searchParameters = {
      'q': '*',
      'query_by': 'slug',
      'include_fields': includeFields,
      'page': 1,
      'sort_by': 'name:asc',
      'filter_by': `status:=ACTIVE && id:[${dids}]`,
      'per_page': 120,
    };
    const results = await typesenseClient.collections(`${appEnv || 'production'}_${collectionName}`).documents().search(searchParameters, {});

    if (results && Array.isArray(results.hits)) {
      return results.hits;
    }

    return [];
  };

  return {
    provide: {
      listing: {
        search: listingSearch,
      },
      development: {
        search: developmentSearch,
      },
      company: {
        search: companySearch,
      },
      featured: {
        set: featured,
      },
      favorites: {
        get: favorites,
      },
      ad: {
        search: adSearch,
      },
    },
  };
});
